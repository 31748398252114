// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/milestone")
require("packs/law_project")
require("packs/commission")

import "bootstrap"

$( document ).on('turbolinks:load', function() {
   
  $('.commission-popover').popover({
    trigger: 'focus'
  })

  flatpickr("#law_project_admission_date", {
    "locale": "es",
    dateFormat: "d-m-Y",
    maxDate: "today",
  });

  if (document.getElementById("milestone_create_date")){
    if (document.getElementById("milestone_create_date").value){
      flatpickr("#milestone_create_date", {
        "locale": "es",
        enableTime: false,
      })
    }else{
      flatpickr("#milestone_create_date", {
        enableTime: false,
        "locale": "es",
        dateFormat: "d-m-Y",
        maxDate: "today",
        defaultDate: "today",
      })
    }
  }
})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
