$( document ).on('turbolinks:load', function() {
    $("#switch_card").on('click', (e) => {
        const selfEvent = $(e.currentTarget)
        if (selfEvent.is(':checked')){
          set_session_switch("true")
          document.getElementById("index_card").style.display = "block"
          document.getElementById("index_table").style.display = "none"
        }else{
          set_session_switch("false")
          document.getElementById("index_card").style.display = "none"
          document.getElementById("index_table").style.display = "block"
        }
    })
    
    function set_session_switch(value){
        $.post('/update_session_switch', { switch: value })
    }
    
    if (document.getElementById("upload_file_to_milestone")){
      if (document.querySelector('[id^="edit_law_project_"]')){
        document.getElementById("upload_file_to_milestone").style.display = "none"
      }
    }

    if (document.querySelector('[id^="law_project_file_project_"]')){
      var project_summary = document.getElementById('law_project_file_project_summary')
      var project_detail = document.getElementById('law_project_file_project_detail')
      var project_changes = document.getElementById('law_project_file_project_changes')

      project_summary.addEventListener("change", function () {
        if (project_summary.files.length > 0) {
          document.getElementById('btn_upload_project_summary').disabled = false
        }
      })

      project_detail.addEventListener("change", function () {
        if (project_detail.files.length > 0) {
          document.getElementById('btn_upload_project_detail').disabled = false
        }
      })

      project_changes.addEventListener("change", function () {
        if (project_changes.files.length > 0) {
          document.getElementById('btn_upload_project_changes').disabled = false
        }
      })
    }
    
})

$(document).on("click", "#table-projects tr", function() {
  var link  = $(this).data("href")
  if(link) {
    window.location.href = link;
  }
});