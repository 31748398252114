
$( document ).on('turbolinks:load', function() {
    if (document.getElementById('milestone_milestone_files') || document.getElementById('law_project_milestone_milestone_files')){
        $('input[type="file"]').change(function(e){
            var fileName = e.target.files[0].name;
            files_list = e.target.files;
            for (var i=0, l=files_list.length; i<l; i++) {
                var input = document.getElementById('text_files');
                if (i === 0){
                    input.value = files_list[i].name;            
                }else{
                    input.value = input.value + ', ' + files_list[i].name;            
                }
            }
        });
    }
    
    if (document.getElementById("milestone_commission_id")){
        var select_type_milestone 
        var select_commission_id
        var value_type_milestone
        var value_commission_id 
        var text_commission_id

        function get_elements_values(){
            select_type_milestone = document.getElementById("milestone_type_milestone")
            select_commission_id = document.getElementById("milestone_commission_id")

            value_type_milestone = select_type_milestone.options[select_type_milestone.selectedIndex].value
            value_commission_id = select_commission_id.options[select_commission_id.selectedIndex].value
            text_commission_id = select_commission_id.options[select_commission_id.selectedIndex].text
        }
        if (document.getElementsByClassName('edit_milestone').length || document.getElementsByClassName('new_milestone').length){
            get_elements_values()
            display_select_commission_id(value_type_milestone)
            display_form_milestone_commission(value_commission_id, text_commission_id, value_type_milestone)
        
            select_type_milestone.addEventListener("change", function() {
                get_elements_values()
                display_select_commission_id(value_type_milestone)
                display_form_milestone_commission(value_commission_id, text_commission_id, value_type_milestone)
            }, false)

            select_commission_id.addEventListener("change", function() {
                get_elements_values()
                display_select_commission_id(value_type_milestone)
                display_form_milestone_commission(value_commission_id, text_commission_id, value_type_milestone)
            }, false)
        }

        function display_form_milestone_commission(value_commission_id, text, value_type_milestone){
            if (value_commission_id === "" && [1, 2, 5].includes(parseInt(value_type_milestone)) && text === 'Otro'){
                document.querySelector('#milestone_commission_select').style.display = 'block'
            } else {
                document.querySelector('#milestone_commission_select').style.display = 'none'
                document.getElementById("milestone_name_commission").value = ""
                document.getElementById("milestone_members_commission").value = ""
            }
        }

        function display_select_commission_id(value_type_milestone){
            if ([1, 2, 5].includes(parseInt(value_type_milestone))){
                document.querySelector('#commissions_select').style.display = 'block'
            } else {
                document.querySelector('#commissions_select').style.display = 'none'
                document.getElementById("milestone_commission_id").selectedIndex = '0'
            }
        }
    }
})