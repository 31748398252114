$( document ).on('turbolinks:load', function() {
    if (document.getElementById("commission_members") || 
        document.getElementById("milestone_members_commission") ||
        document.getElementById("milestone_commission_members") ){
            
        // Create a "close" button and append it to each list item
        var myNodelist = document.querySelectorAll('li.member');
        var i;
        for (i = 0; i < myNodelist.length; i++) {
            var span = document.createElement("SPAN");
            var txt = document.createTextNode("\u00D7");
            span.className = "close-member";
            span.appendChild(txt);
            myNodelist[i].appendChild(span);
        }

        // Click on a close button to hide the current list item
        var close = document.getElementsByClassName("close-member");
        var i;
        for (i = 0; i < close.length; i++) {
            close[i].onclick = function() {
                var div = this.parentElement;
                //div.style.display = "none";
                div.remove();
                send_to_textarea_list();
            }
        }

        // Create a new list item when clicking on the "Add" button
        document.getElementById("btn_newElement").addEventListener("click", function() {
            var li = document.createElement("li");
            var inputValue = document.getElementById("add_member").value;
            var t = document.createTextNode(inputValue);
            li.appendChild(t);
            if (inputValue === '') {
                alert("Debe ingresar un nombre");
            } else {
                document.getElementById("myUL").appendChild(li);
            }
            document.getElementById("add_member").value = "";

            var span = document.createElement("SPAN");
            var txt = document.createTextNode("\u00D7");
            span.className = "close-member";
            span.appendChild(txt);
            li.appendChild(span);

            for (i = 0; i < close.length; i++) {
                close[i].onclick = function() {
                    var div = this.parentElement;
                    //div.style.display = "none";
                    div.remove();
                    send_to_textarea_list();
                }
            }
            send_to_textarea_list();
        })

        function send_to_textarea_list(){
            var lis = document.getElementById("myUL").getElementsByTagName("li");
            var elementForm = document.getElementById("commission_members");
            if (elementForm == null){
                elementForm = document.getElementById("milestone_members_commission");
                elementForm.value = "";
            }else{
                elementForm.value = "";
            }
            
            for (var i = 0; i < lis.length; i++) {
                if (i == 0){
                    elementForm.value += lis[i].innerHTML.split("<", 1);
                }else{
                    elementForm.value += "," + lis[i].innerHTML.split("<", 1);
                }
            }
        } 
    }
})